import mutations from './mutations'
import actions from './actions'

export interface IState {
  encourageEnable: boolean
  normalAwardAmount: number
  normalAwardName: string
  noticeDuration: number
  unlockLoginAchievement: boolean
  loginAchievement: ILoginAchievement | null
  todayEncourageList: ITodayEncourage[]
  minimalGiftPrice: number
  classroomCheckpoint: any
}

export interface ILoginAchievement {
  nickname: string
  welcomeDesc: string
  achievementDesc: string
  normalAward: boolean
  awardName: string
  awardCount: number
}

export interface ITodayEncourage {
  achievement_desc: string
  normal_encourage: boolean
  normal_award: boolean
  award_name: string
  award_amount: number
  include_special_encourage: boolean
  special_encourage: {
    times_requirement: number
    current_times: number
  }
  scene: string
  scene_data: {
    app_id: number
    package_id: string
    checkpoint_index: number
  }
}

const state: IState = {
  encourageEnable: false,
  normalAwardAmount: 0,
  normalAwardName: '',
  noticeDuration: 0,
  unlockLoginAchievement: false,
  loginAchievement: null,
  todayEncourageList: [],
  minimalGiftPrice: 0,
  classroomCheckpoint: null
}

export default {
  namespaced: true,
  actions,
  mutations,
  state
}
