import Vue from 'vue'
import Vuex from 'vuex'
import { RootState } from './types'
import actions from './actions'
import mutations from './mutations'

Vue.use(Vuex)

import modal from './modules/modal'
import encourage from './modules/encourage'

export default new Vuex.Store<RootState>({
  modules: {
    modal,
    encourage
  },
  state: {
    user: null
  },
  actions,
  mutations,
  strict: true
})
