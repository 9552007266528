import { MutationTree } from 'vuex'
import { IState, ICallbacks } from './type'
const mutations: MutationTree<IState> = {
  setLogin(state, flag: boolean) {
    state.isShowLogin = flag
  },
  setBind(state, flag: boolean) {
    state.isShowBind = flag
  },
  setLoginCb(state, callbacks: ICallbacks) {
    state.loginCallback = callbacks
  },
  setBindCb(state, callbacks: ICallbacks) {
    state.bindCallback = callbacks
  }
}

export default mutations
