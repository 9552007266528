const enrolment = 'api-enrollment-go'
const academy = 'api-academy-go'
const encourage = 'encourage-market'

export default {
  getUser: {
    url: '/user/info',
    server: academy
  },
  getQrcode: {
    url: '/login/qrcode',
    server: academy,
    params: {
      platform: 'lion'
    }
  },
  pollingQrcode: {
    url: '/login/qrcode/:id',
    server: academy,
    params: {
      platform: 'lion'
    }
  },
  getPhoneCountry: {
    url: '/phone-country-code',
    server: academy
  },
  getValidateCode: {
    url: '/binding/phone/code',
    method: 'post',
    server: academy
  },
  postBindPhoneData: {
    url: '/binding/phone',
    method: 'post',
    server: academy,
    params: {
      platform: 'lion'
    }
  },
  // enrolment
  postProtocol: {
    url: '/user-protocol',
    method: 'post',
    server: enrolment
  },
  // enrolment-go 项目
  postAssistantFeedback: {
    url: '/web/enrolment/feedback',
    method: 'post',
    server: enrolment
  },

  // encourage-market 激励方案
  getTodayEncourages: {
    url: '/today-encourages',
    server: encourage
  },
  getEncourageInfos: {
    url: '/infos',
    server: encourage
  }
}
