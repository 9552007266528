import { MutationTree } from 'vuex'
import { IState } from './index'

const mutations: MutationTree<IState> = {
  setNormalAwardName(state, payload) {
    state.normalAwardName = payload.awardName
  },

  setNormalAwardAmount(state, payload) {
    state.normalAwardAmount = payload.awardAmount
  },

  setEncourageconfig(state, payload) {
    state.encourageEnable = payload.encourageEnable
    state.normalAwardAmount = payload.normalAwardAmount
    state.normalAwardName = payload.normalAwardName
    state.noticeDuration = payload.noticeDuration
    state.unlockLoginAchievement = payload.unlockLoginAchievement
    state.minimalGiftPrice = payload.minimalGiftPrice
  },

  setTodayEncouragesConfig(state, payload) {
    state.todayEncourageList = payload.todayEncourageList
    state.classroomCheckpoint = payload.classroomCheckpoint
  },

  setLoginAchievement(state, payload) {
    state.loginAchievement = {
      nickname: payload.nickname,
      welcomeDesc: payload.welcome_desc,
      achievementDesc: payload.achievement_desc,
      normalAward: payload.normal_award,
      awardName: payload.award_name,
      awardCount: payload.award_count
    }
  },

  addNormalAwardAmount(state, payload) {
    state.normalAwardAmount = state.normalAwardAmount + payload.amount
  }
}

export default mutations
