export default {
  getTrainingConfig: {
    url: '/training/web/apply'
  },
  postPayment: {
    url: '/payment/production/charge/:id',
    method: 'post'
  },
  getPayStatus: {
    url: '/training/web/user/:id'
  }
}
