import Vue from 'vue'
import Router from 'vue-router'
import { getCookie, setToken, unsetToken } from '@/lib/utils'
import vuex from '@/store'
import Upgrade from '@/pages/upgrade/index.vue'

Vue.use(Router)

// let routes: any[] = []

// try {
//   const context = require.context(`../pages`, true, /.*\/routes\.tsx?$/)
//   context.keys().forEach((key: string) => {
//     const route = context(key).default
//     routes = routes.concat(route)
//   })
// } catch (err) {
//   console.warn(err.message)
// }

// routes.push({
//   path: '/',
//   redirect: '/leo-home'
// })
// routes.push({
//   path: '*',
//   redirect: '/leo-home'
// })
// routes.push({
//   path: '/app-center',
//   redirect: '/app-center'
// })

// console.log('>>> routes: ', JSON.stringify(routes))

const router = new Router({
  routes: [
    { path: '/upgrade', component: Upgrade },
    { path: '*', redirect: '/upgrade' }
  ],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

router.beforeEach((to, from, next) => {
  const queryToken = to.query.token
  let token

  if (to.query && (to.query.token || to.query.logout)) {
    let _hash = window.location.hash

    if (queryToken && typeof queryToken === 'string') {
      setToken(queryToken)
      token = queryToken
      _hash = _hash.replace(/token=[^&]*&?/, '')
    }

    // 从url上取值都是字符串，因此是字符串 "true"
    if (to.query.logout && to.query.logout === 'true') {
      _hash = _hash.replace(/logout=[^&]*&?/, '')
      unsetToken()
    }

    next({
      path: _hash.replace(/^#/, ''),
      replace: true
    })
    return
  }

  token = getCookie('token')

  if (token && !vuex.state.user) {
    vuex.dispatch('getUser').finally(() => {
      next()
    })
  } else {
    next()
  }
})

export default router
