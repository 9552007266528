export default {
  getFeedbackStatus: {
    url: '/web/feedback/status',
    server: 'api-feedback-go'
  },
  collectIndex: {
    url: '/web/collect/index',
    server: 'api-academy-go'
  },
  collect: {
    url: '/web/collect',
    method: 'post',
    server: 'api-academy-go'
  },
  getFinishTitle: {
    url: '/user/finish-title',
    server: 'api-academy-go'
  },
  getAppstore: {
    url: '/appstore',
    params: {
      platform: 'lion'
    },
    server: 'api-academy-go'
  },
  getApps: {
    url: '/user/apps',
    server: 'api-academy-go'
  },
  getCheckPoint: {
    url: '/user/checkpoint',
    server: 'api-academy-go'
  },
  getBannerGuide: {
    url: '/banner/guide',
    server: 'api-academy-go'
  }
}

// export function getFeedbackStatus() {
//   return api.get('/web/feedback/status', { baseURL: FeedbackUrl })
// }

// export function getCollect() {
//   return api.get('/web/collect/index', { baseURL: FeedbackUrl })
// }

// export function postCollect(data: any) {
//   return api.post('/web/collect', data, { baseURL: FeedbackUrl })
// }

// export function getFinishTitle() {
//   return api.get('/user/finish-title')
// }
