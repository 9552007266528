import * as fundebug from 'fundebug-javascript'
import fundebugVue from 'fundebug-vue'
import 'fundebug-revideo'

type fd = typeof fundebug
const filters: Object[] = [
  {
    res: {
      status: /^(401|0)$/
    }
  },
  {
    type: /^unhandledrejection$/,
    // 过滤由于 401 等引起的一系列 promise 错误
    message: {
      status: /^(4|5)\d\d$/
    }
  },
  {
    type: /^resourceError$/
  },
  {
    type: /^uncaught$/,
    fileName: /vendor/
    // 插件黑盒错误
  }
]
export default function installFundebug(Vue: any) {
  return new Promise<fd>(resolve => {
    if (['TEST', 'STAGE', 'PROD'].indexOf(window.ENV) > -1) {
      fundebug.init({
        appversion: window.RELEASE,
        apikey: '1e39b4da847442247653a64ff2cf86e06c856243f5659e9ba3b192eefa1c3382',
        releasestage: window.ENV,
        setHttpBody: true,
        filters
      })
      fundebugVue(fundebug, Vue)

      resolve(fundebug)
    } else {
      resolve({
        init() {},
        test() {},
        notify() {},
        notifyError() {}
      } as fd)
    }
  })
}
