














import { Vue, Component } from 'vue-property-decorator'

@Component({
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vm: any) => {
      document.title = 'Python训练营'
    })
  }
})
export default class Upgrade extends Vue {
  go() {
    if (window.ENV === 'PROD') window.location.replace('https://www.pypypy.cn/app#/')
    else window.location.replace('https://test.pypypy.cn/web-fos/entry/app#/')
  }
}
