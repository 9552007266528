import env from './env'

const serverMap: IServerMap = {
  // 麒麟
  baseServer: {
    baseMap: {
      localprod: '',
      prod: 'https://prod.pandateacher.com/python-class-fos-kylin/gw-sale',
      stage: 'https://stage.pandateacher.com/python-class-fos-kylin/gw-sale',
      test: 'https://test.pandateacher.com/python-class-fos-kylin/gw-sale',
      dev: 'https://dev.pandateacher.com/python-class-fos-kylin/gw-sale' + window.CI_DEV_BRANCH,
      local: 'http://127.0.0.1:4320'
    },
    default: true
  },
  // 学习系统
  'api-academy-go': {
    // 学习系统相关代码
    baseMap: {
      localprod: '',
      prod: 'https://prod.pandateacher.com/python-class-fos/api-academy-go',
      stage: 'https://stage.pandateacher.com/python-class-fos/api-academy-go',
      test: 'https://test.pandateacher.com/python-class-fos/api-academy-go',
      dev: 'https://dev.pandateacher.com/python-class-fos/api-academy-go' + window.CI_DEV_BRANCH,
      local: `http://127.0.0.1:4320`
    }
  },
  // 报名系统
  'api-enrollment-go': {
    baseMap: {
      localprod: '',
      prod: 'https://prod.pandateacher.com/python-class-fos/api-enrollment-go',
      stage: 'https://stage.pandateacher.com/python-class-fos/api-enrollment-go',
      test: 'https://test.pandateacher.com/python-class-fos/api-enrollment-go',
      dev: 'https://dev.pandateacher.com/python-class-fos/api-enrollment-go' + window.CI_DEV_BRANCH,
      local: `http://127.0.0.1:4320`
    }
  },
  // feedback
  'api-feedback-go': {
    baseMap: {
      localprod: '',
      prod: 'https://prod.pandateacher.com/python-class-fos/api-feedback-go',
      stage: 'https://stage.pandateacher.com/python-class-fos/api-feedback-go',
      test: 'https://test.pandateacher.com/python-class-fos/api-feedback-go',
      dev: 'https://dev.pandateacher.com/python-class-fos/api-feedback-go' + window.CI_DEV_BRANCH,
      local: `http://127.0.0.1:4320`
    }
  },
  'encourage-market': {
    baseMap: {
      localprod: '',
      prod: 'https://prod.pandateacher.com/python-class-fos/encourage-market',
      stage: 'https://stage.pandateacher.com/python-class-fos/encourage-market',
      test: 'https://test.pandateacher.com/python-class-fos/encourage-market',
      dev: 'https://dev.pandateacher.com/python-class-fos/encourage-market' + window.CI_DEV_BRANCH,
      local: `http://127.0.0.1:4320`
    }
  }
}

export default serverMap

interface IBaseMap {
  localprod: string
  prod: string
  stage: string
  test: string
  dev: string
  local: string
  [key: string]: any
}

interface IConfig {
  default?: boolean
  baseURL?: string
  baseMap: IBaseMap
}

interface IServerMap {
  [key: string]: IConfig
}
