import { IState } from './type'
import actions from './actions'
import mutations from './mutations'
const state: IState = {
  loginCallback: { success: null, fail: null },
  bindCallback: { success: null, fail: null },
  isShowBind: false,
  isShowLogin: false
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
