export default {
  getHomeInfo: {
    method: 'get',
    url: '/training/web/index'
  },
  getRedirectUrl: {
    method: 'get',
    url: '/training/web/info'
  },
  getAllApps: {
    method: 'get',
    url: '/index/apps',
    params: {
      platform: 'lion'
    },
    server: 'api-academy-go'
  }
}
