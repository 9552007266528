import { ActionTree } from 'vuex'
import { IState } from './index'
import { RootState } from '@/store/types'

const actions: ActionTree<IState, RootState> = {
  getEncourageConfig({ commit }) {
    const api = window.apis.common
    return Promise.all([api.getEncourageInfos(), api.getTodayEncourages()]).then(([info, todayEncourages]) => {
      if (info && info.data) {
        commit('setEncourageconfig', {
          encourageEnable: info.data.encourage_enable,
          normalAwardName: info.data.normal_award_name,
          normalAwardAmount: info.data.normal_award_amount,
          noticeDuration: info.data.notice_duration,
          unlockLoginAchievement: info.data.unlock_login_achievement,
          minimalGiftPrice: info.data.minimal_gift_price
        })

        if (info.data.unlock_login_achievement && info.data.login_achievement) {
          commit('setLoginAchievement', info.data.login_achievement)
        }
      }

      if (todayEncourages && todayEncourages.data) {
        // 若未完成今日任务则清空弹窗记录
        if (
          todayEncourages.data.encourages &&
          todayEncourages.data.encourages.length > 0 &&
          localStorage.getItem('askPopoverShow')
        ) {
          localStorage.removeItem('askPopoverShow')
        }

        commit('setTodayEncouragesConfig', {
          todayEncourageList: todayEncourages.data.encourages || [],
          classroomCheckpoint: todayEncourages.data.classroom_checkpoint
        })
      }
    })
  },

  updateTodayEncourages({ commit }) {
    const api = window.apis.common
    return api.getTodayEncourages().then((res: any) => {
      if (res && res.data) {
        // 若未完成今日任务则清空弹窗记录
        if (res.data.encourages && res.data.encourages.length > 0 && localStorage.getItem('askPopoverShow')) {
          localStorage.removeItem('askPopoverShow')
        }

        commit('setTodayEncouragesConfig', {
          todayEncourageList: res.data.encourages || [],
          classroomCheckpoint: res.data.classroom_checkpoint
        })
      }
    })
  }
}
export default actions
