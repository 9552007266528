import { ActionTree } from 'vuex'
import { IState } from './type'
import { RootState } from '@/store/types'
import { setCookie, getCookie, getToken } from '@/lib/utils'

const actions: ActionTree<IState, RootState> = {
  invokeLogin({ state, commit, rootState }) {
    const token = getCookie('token')
    if (rootState.user && token) {
      return Promise.resolve()
    }
    let rs, rj
    const promise = new Promise((resolve, reject) => {
      rs = resolve
      rj = reject
    })
    commit('setLoginCb', { success: rs, fail: rj })
    commit('setLogin', true)
    return promise
  },
  loginSuccess({ state, dispatch, commit }, token: string) {
    // 处理 token 存储
    return dispatch('login', token, { root: true }).finally(() => {
      const calback = state.loginCallback
      calback && calback.success && calback.success()
    })
    // return dispatch('getUser', null, { root: true }).finally(() => {
    //   commit('setLoginCb', { success: null, fail: null })
    // })
  },
  loginFail({ state, commit }) {
    const cb = state.loginCallback
    cb.fail && cb.fail()
    commit('setLoginCb', { success: null, fail: null })
  },
  invokeBindPhone({ commit, rootState }) {
    if (rootState.user && rootState.user.phoneBinding) {
      return Promise.resolve(getToken())
    }
    let rs, rj
    const promise = new Promise((resolve, reject) => {
      rs = resolve
      rj = reject
    })
    commit('setBindCb', { success: rs, fail: rj })
    commit('setBind', true)
    return promise
  },
  bindSuccess({ state, dispatch }, token: string) {
    const callback = state.bindCallback
    callback && callback.success && callback.success(token)
    return dispatch('login', token, { root: true })
  },
  bindFail({ state, commit }) {
    const cb = state.bindCallback
    cb && cb.fail && cb.fail()
    commit('setBindCb', { success: null, fail: null })
  }
}

export default actions
