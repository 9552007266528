// export function setCookie(
//   name: string,
//   value: string,
//   expires?: Date,
//   path?: string,
//   domain?: string,
//   secure?: boolean
// ) {
//   let cookieText = encodeURIComponent(name) + '=' + encodeURIComponent(value)

//   if (expires instanceof Date) {
//     cookieText += '; expires=' + expires.toUTCString()
//   }

//   if (path) {
//     cookieText += '; path=' + path
//   }

//   if (domain) {
//     cookieText += '; domain=' + domain
//   }

//   if (secure) {
//     cookieText += '; secure'
//   }
//   document.cookie = cookieText
// }

// export function unsetCookie(
//   name: string,
//   path?: string,
//   domain?: string,
//   secure?: boolean
// ) {
//   path = path || window.location.pathname
//   domain = domain || window.location.hostname
//   const source = path.replace(/^\/|\/$/g, '').split('/')
//   let pre = ``
//   const paths = source.map((item, index) => {
//     pre = `${pre}/${item}`
//     return pre
//   })
//   paths.forEach(p => {
//     setCookie(name, '', new Date(0), p, domain, secure)
//     setCookie(name, '', new Date(0), p, `.` + domain, secure)
//   })
// }

import Cookies from 'js-cookie'
import qs from 'qs'
export function getCookie(name: string) {
  return Cookies.get(name)
}

export function setCookie(
  name: string,
  value: string,
  options: Cookies.CookieAttributes = {
    expires: 7
  }
) {
  if (!name || !value) {
    console.error('setCookie 执行出错，参数 name 与 value 必须存在')
    return
  }
  Cookies.set(name, value, options)
}

export function unsetCookie(name: string, options?: Cookies.CookieAttributes) {
  Cookies.remove(name, options)
}

export function base64Decode(str: string) {
  str = window
    .atob(str)
    .split('')
    .map((c: string) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    })
    .join('')
  return decodeURIComponent(str)
}

export function compose(f: (...args: any[]) => any, g: (...args: any[]) => any) {
  return function composeWrap(x: any) {
    return f(g(x))
  }
}

export function getTokenKey() {
  const env = window.ENV ? window.ENV.toLowerCase() : 'local'

  const key = 'token'
  // if (env !== 'local') {
  // key = `${env}-auth-token`
  // }

  return key
}

export function getToken() {
  const key = getTokenKey()
  return getCookie(key)
}

export function getJWT(check: boolean = false) {
  const token = getToken()
  return decodeToken(token || '')
}
export function decodeToken(token: string) {
  let payload = ''
  let jwt = null
  if (token) {
    payload = token.split('.')[1]
  }
  try {
    jwt = JSON.parse(base64Decode(payload))
  } catch (e) {
    return null
  }
  return jwt
}

export function parseQuery(key: string) {
  const queryStr = window.location.search.replace('?', '')
  const queryMap: any = {}

  for (const param of queryStr.split('&')) {
    const [key, value] = param.split('=')
    queryMap[key] = value
  }

  return queryMap[key]
}

export function validAry(ary: any[]) {
  return ary && Array.isArray(ary) && ary.length
}

export function addHashQuery(url: string, outQuery: Object) {
  const reg = /^([^?#]*)(\?(?:[^#]*))?(#(?:.*))?/
  const result = url.match(reg)
  if (result) {
    const host = result[1]
    const search = result[2]
    let hash = result[3]
    if (hash) {
      const [path, query] = hash.split('?')
      let resultQueryString = ''
      if (query) {
        let queryObj: any = qs.parse(query)
        queryObj = { ...queryObj, ...outQuery }
        resultQueryString = qs.stringify(queryObj)
      } else {
        resultQueryString = qs.stringify(outQuery)
      }
      resultQueryString = resultQueryString ? '?' + resultQueryString : ''
      hash = path + resultQueryString
    }
    return host + (search || '') + (hash || '')
  }
  console.log(url + '?' + qs.stringify(outQuery), '你号没了')
  return url + '?' + qs.stringify(outQuery)
}
export const setToken = (token: string) => {
  setCookie('token', token, {
    expires: 8,
    path: window.location.pathname
  })
}
export const unsetToken = () => {
  unsetCookie('token', {
    path: window.location.pathname
  })
  let result: string
  const cookiePath = window.location.pathname
  const source = cookiePath.split('/')
  if (source.length > 1) {
    result = cookiePath.slice(0, -1)
    unsetCookie('token', {
      path: result
    })
  }
  unsetCookie('token')
}

// 数字转中文
export const indexToCN: (val: number) => string = (function() {
  const countMap: string[] = ['', '一', '二', '三', '四', '五', '六', '七', '八', '九']
  // 只可以兼容到万
  const carryMap: string[] = ['', '十', '百']

  return function(val: number) {
    if (val === 0) {
      return '零'
    }

    let carry = -1
    let result = ''
    let zeroInTail = val % 10 === 0
    let zeroInMiddle = zeroInTail
    let remainder = -1

    while (val > 0) {
      carry += 1
      remainder = val % 10
      if (remainder !== 0) {
        const countChar = carry === 1 && remainder === 1 ? '' : countMap[remainder]
        if (zeroInTail) {
          result = countChar + carryMap[carry]
        } else {
          result = countChar + carryMap[carry] + (zeroInMiddle ? '零' : '') + result
        }
        zeroInTail = false
        zeroInMiddle = false
      } else {
        zeroInMiddle = true
      }
      /* tslint:disable:no-bitwise */

      val = ~~(val / 10)

      /* tslint:enable:no-bitwise */
    }
    return result
  }
})()

export function isDeObj(obj: object) {
  return !!obj && typeof obj === 'object' && JSON.stringify(obj) !== '{}'
}
