import * as Sentry from '@sentry/browser'
import { getJWT } from './utils'
import { SentryError } from '@sentry/core'

export default function initSentry(_Vue: any) {
  if (window.ENV === 'DEV' || process.env.NODE_ENV === 'development' || window.SENTRY_DSN == null) {
    return
  }
  const isDebugMode = /error=1/.test(window.location.search)

  let id = 'visitor'
  try {
    const jwt = getJWT()
    id = jwt.oid
  } catch (error) {
    console.log(`游客模式`, error)
  }

  try {
    console.log('sentry init')
    Sentry.init({
      dsn: window.SENTRY_DSN || '',
      release: window.RELEASE,
      debug: isDebugMode,
      environment: window.ENV || 'DEV',
      integrations: [
        new Sentry.Integrations.Vue({
          Vue: _Vue
        })
      ],

      beforeSend(event, hint) {
        console.log('>>>>> beforeSend\n')
        hint && hint.originalException && console.log(JSON.stringify(hint.originalException))
        // 过滤401错误
        if (hint && hint.originalException && (hint.originalException as any).status === 401) {
          return null
        }
        return event
      }
    })

    Sentry.configureScope(scope => {
      scope.setUser({
        id
      })
    })
  } catch (error) {
    console.error(`Init sentry error,`, error)
  }

  window.Sentry = Sentry
}
