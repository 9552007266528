// import api from '@/modules/protocol/node_modules/@/api'

// export function getProtocol(query: any) {
//   return api.get(`/user-protocol`, query)
// }
const server = 'api-academy-go'
export default {
  getProtocol: {
    url: '/user-protocol',
    server,
    params: {
      platform: 'lion'
    }
  }
}
