import Vue from 'vue'
import App from './app.vue'
import router from './router'
import store from '../store'

import apis from '@lib/apis'
import sensors from '@lib/sensors'
import installSentry from '@lib/init-sentry'
import installFundebug from '@lib/init-fundebug'
import * as Sentry from '@sentry/browser'

declare global {
  interface Window {
    Sentry: typeof Sentry
  }
}

window.apis = apis
window.sensors = sensors

installSentry(Vue)
installFundebug(Vue)

const vue = new Vue({
  el: '#app',
  store,
  router,
  components: { App },
  template: '<App/>'
})

export default vue
