import Apis from '@forchange/apis'
import { getToken, parseQuery, unsetToken, setToken } from './utils'
import router from '@/main/router'
import env from '../config/env'
import serverMap from '../config/apis'
// 整理 apiMap
const apiMap = {}
const testApiMap = {}

try {
  const modules = require.context(`../pages`, true, /.*\/apis\.tsx?$/)
  // const componentsApis = require.context(`../components/common`, false, /.*\/apis\.tsx?$/)

  const combineApis = (modules: __WebpackModuleApi.RequireContext, key: string) => {
    const config = modules(key).default
    const namespace = key.replace(/\.\/(.*?)\/apis.ts/, '$1').includes('apis')
      ? 'common'
      : key.replace(/\.\/(.*?)\/apis.ts/, '$1')

    Object.keys(config).forEach((_key: string) => {
      Object.assign(testApiMap, {
        [`${namespace}/${_key}`]: {
          ...config[_key],
          namespace,
          initParams: config[_key].params || {}
        }
      })
    })

    Object.assign(apiMap, testApiMap)
  }

  modules.keys().forEach(item => combineApis(modules, item))
  // componentsApis.keys().forEach(item => combineApis(componentsApis, item))
} catch (err) {
  console.error(err.message)
}

// 整理 serverMap
for (const key of Object.keys(serverMap)) {
  let baseURL = serverMap[key].baseMap[env.ENV]

  // if (env.ENV === 'dev') {
  //   baseURL = `${baseURL}${window.CI_DEV_BRANCH}`
  // }

  // host
  const dHost = parseQuery('host')
  if (dHost) {
    baseURL = dHost
  }

  serverMap[key].baseURL = baseURL
}

Apis.useReq((config: any) => {
  let token = getToken()

  // token
  const dToken = parseQuery('token')
  if (dToken) {
    token = dToken
    setToken(dToken)
  }

  // clear
  const dClear = parseQuery('clear')
  if (dClear) {
    token = ''
    unsetToken()
  }

  config.headers.Authorization = `Bearer ${token}`
  return config
})

Apis.useReq((config: any) => {
  if (config.params) {
    config.params = Object.assign(config.params, config.initParams)
  }
  return config
})

Apis.useRes(
  (res: any) => {
    const { config } = res
    if (config && config.raw) {
      return res
    }
    return res.data
  },
  (err: any) => {
    console.log('err>>>>>>>>>>>', err)
    if (!err.response) {
      return Promise.reject(err)
    }
    const status = err.response.status
    if (status === 401) {
      unsetToken()
      router.replace({
        path: '/'
      })
    }
    if (status >= 500) {
      alert(`服务器正在升级中...`)
    }
    return Promise.reject(err.response)
  }
)

const apis = new Apis(serverMap, apiMap)

export default apis
