import { RootState, IUser } from './types'
import { MutationTree } from 'vuex'
import { unsetToken } from '@lib/utils'
const rootMutations: MutationTree<RootState> = {
  setUser(state, user: IUser) {
    state.user = user
  },
  clearUser(state) {
    state.user = null
    state.jwt = null
    unsetToken()
  }
}
export default rootMutations
