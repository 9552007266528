/**
 * Created by xiejs on 2017/6/8
 */
import * as Tracker from 'sa-sdk-javascript'
const TrackerDynamic = () => import('sa-sdk-javascript')

interface Fake {
  track: typeof Tracker.track
  logout: typeof Tracker.logout
  login: typeof Tracker.login
}

import { getJWT } from './utils'
import { Route } from 'vue-router'

const config: any = {
  sdk_url: 'https://baseqcdn.pandateacher.com/sensor/vtrack.min.js',
  name: 'tracker',
  server_url: 'https://sensorpub.pandateacher.com:4006/sa?project=production',
  config_url: 'https://sensorpub.pandateacher.com:4006/config/?project=production',
  vtrack_js_file: 'https://baseqcdn.pandateacher.com/sensor/vendor.min.js',
  vtrack_css_file: 'https://baseqcdn.pandateacher.com/sensor/vendor.min.css',
  web_url: 'https://sensorpub.pandateacher.com:4007/?project=production',
  heatmap: {},
  is_track_single_page: true
}

const trackerLoaded: Promise<typeof Tracker | Fake> = new Promise((rs, rj) => {
  const jwt = getJWT()
  // 生产、预发布、测试环境
  if (window.ENV !== 'DEV') {
    setTimeout(async () => {
      TrackerDynamic().then(tracker => {
        tracker.init(config)
        if (jwt && jwt.oid) {
          tracker.login(jwt.oid)
        }
        tracker.quick('autoTrack')
        rs(tracker)
      })
    }, 300)
  } else {
    const track: typeof Tracker.track = (event: any, options: any) => {
      console.debug(`--------------- event: ${event} --------- key nums: ${Object.keys(options).length}-------------`)
      console.log(`[sensor-tracker]: The event is ${event},\n and the options:`)
      console.table(options)
    }
    const fake: Fake = {
      track,
      logout() {
        console.log('track logout')
      },
      login(oid: string) {
        console.log('track login ', oid)
      }
    }
    rs(fake)
  }
})

export function patchTracker(tracker: typeof Tracker) {
  const old = tracker
  const jwt = getJWT()
  const track: typeof Tracker.track = function(event: any, options: any) {
    tracker.logout()
    tracker.login(jwt.oid)
    const result = tracker.track(event, options)
    tracker.logout()
    tracker.login(jwt.sub)
    return result
  }
  if (process.env.NODE_ENV === 'development') {
    const track: typeof Tracker.track = (event: any, options: any) => {
      console.log(`[sensor-tracker]: The event is ${event},\n and the options: ${options}`)
    }
    const fake: Fake = {
      track,
      logout() {},
      login() {}
    }
  }
  return {
    track
  }
}

export function makeTracker(defaultPayload: any) {
  !defaultPayload && (defaultPayload = {})
  return function(event: string, payload: any) {
    !payload && (payload = {})
    const result = Object.assign({}, payload, defaultPayload)
    console.log(result)
    trackerLoaded.then(tracker => {
      tracker.track(event, result)
    })
  }
}
export function generateFromParams(route: Route) {
  return {
    from: route.query.From || 'unknown',
    from_version: route.query.FromVersion || 'unknown'
  }
}

export default trackerLoaded
