// import { default as api, EnrollmentUrl } from '@/modules/privacy/node_modules/@/api'

// export function getPrivacy() {
//   return api.get(`/web/user-privacy`, {
//     baseURL: EnrollmentUrl
//   })
// }
const server = 'api-enrollment-go'
export default {
  getPrivacy: {
    url: '/web/user-privacy',
    server,
    params: {
      platform: 'lion'
    }
  }
}
