var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-upgrade" }, [
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c("div", { staticClass: "page-upgrade-btn", on: { click: _vm.go } }, [
      _vm._v("进入新系统")
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "page-upgrade-title" }, [
      _c("span", [_vm._v("系统升级公告")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-upgrade-info" }, [
      _c("p", { staticClass: "username" }, [_vm._v("亲爱的学员:")]),
      _vm._v(" "),
      _c("p", { staticClass: "content" }, [
        _vm._v(
          "\n      由于我们进行了一次系统迁移，当前旧的课程系统已停止服务。对于已购买课程的学员，我们已将课程迁移到新的课程系统，您可以继续学习。\n    "
        )
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "sign" }, [_vm._v("Python训练营")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }