import { ActionTree } from 'vuex'
import { IUser, RootState } from './types'
import { unsetCookie, decodeToken, setCookie, setToken, unsetToken } from '@/lib/utils'
import trackerLoaded from '@/lib/tracker'

const rootActions: ActionTree<RootState, RootState> = {
  getUser({ commit }) {
    const apis = window.apis.common
    return apis
      .getUser()
      .then((res: any) => {
        const { data } = res
        const user: IUser = {
          nickname: data.nickname,
          phoneBinding: data.phone_binding,
          headimgurl: data.headimgurl,
          inTrainingGroup: data.in_training_group,
          appCenterUrl: data.app_center_url
        }
        commit('setUser', user)
        return user
      })
      .catch(() => {
        console.log('获取用户信息失败')
      })
  },
  logout({ commit }) {
    unsetToken()
    commit('setUser', null)
    trackerLoaded.then(tracker => {
      tracker.logout()
    })
  },
  login({ state, commit, dispatch }, token: string) {
    setToken(token) // 设置 cookie
    // 埋点登录
    const jwt = decodeToken(token)
    if (jwt && jwt.oid) {
      trackerLoaded.then(tracker => {
        tracker.login(jwt.oid)
      })
    }
    // 用户信息获取
    if (!state.user) {
      return dispatch('getUser', token)
    } else {
      return Promise.resolve(token)
    }
  }
}

export default rootActions
