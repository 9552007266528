






import Vue from 'vue'

import '@style/reset.css'
export default Vue.extend({
  name: 'app'
})
